<template>
  <th-page-wrapper>
    <el-form ref="form" :model="form" :rules="rules" class="p-8">
      <timetracking-form ref="timetrackingForm" v-model="form" />
    </el-form>

    <template #actions>
      <settings-toolbar
        :reset-disabled="resetDisabled"
        :save-disabled="resetDisabled"
        @save="handleSave"
      />
    </template>
  </th-page-wrapper>
</template>

<script>
import SettingsToolbar from '../components/settings-toolbar'
import TimetrackingForm from './form'
import compare from 'just-compare'
import parse from 'date-fns/parse'
import th from '@tillhub/javascript-sdk'

function generateDefault() {
  return {
    auto_clock_out: false,
    clockout_after_enabled: false,
    auto_clock_out_after: null,
    clockout_at_enabled: false,
    auto_clock_out_at: null,
    clockout_at: '00:00:00+00:00',
    clockout_after: 12
  }
}

export default {
  name: 'TimetrackingSettings',
  metaInfo() {
    return {
      title: this.$t('nav.main.items.settings.timetracking')
    }
  },
  components: {
    TimetrackingForm,
    SettingsToolbar
  },
  beforeRouteUpdate(to, from, next) {
    //reset changes in settings that were not saved
    this.resetChanges()
    next()
  },
  beforeRouteLeave(to, from, next) {
    //reset changes in settings that were not saved
    this.resetChanges()
    next()
  },
  data() {
    return {
      form: {},
      pristineForm: {}
    }
  },
  computed: {
    isDirty() {
      return !compare(this.pristineForm, this.form)
    },
    resetDisabled() {
      return !this.isDirty
    },
    rules() {
      return {
        clockout_after: [
          {
            required: this.form.clockout_after_enabled,
            message: this.$t(
              'pages.settings.timetracking.edit.clockout.after.required'
            ),
            trigger: 'blur'
          }
        ],
        clockout_at: [
          {
            required: this.form.clockout_at_enabled,
            message: this.$t(
              'pages.settings.timetracking.edit.clockout.at.required'
            ),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    async fetch() {
      try {
        const { data } = await th.timetracking().getConfiguration()
        this.form = data ? this.formatFetchBody(data) : generateDefault()
        this.pristineForm = this.$deepClone(this.form)
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.configuration.singular')
          })
        })
      }
    },
    handleReset() {
      this.form = this.$deepClone(this.pristineForm)
    },
    async handleSave() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const isNew = !this.form.id
        const resource = this.$t('common.resource.configuration.singular')
        const successMessage = isNew
          ? this.$t('common.success.action.create.single', { resource })
          : this.$t('common.success.action.update.single', { resource })

        const errorMessage = isNew
          ? this.$t('common.error.action.create.single', { resource })
          : this.$t('common.error.action.update.single', { resource })

        try {
          const payload = this.makeHandleableBody(this.form)
          const { data } = isNew
            ? await th.timetracking().createConfiguration(payload)
            : await th.timetracking().updateConfiguration(this.form.id, payload)
          this.form = this.formatFetchBody(data)
          this.pristineForm = this.$deepClone(this.form)
          this.$message({
            message: successMessage,
            type: 'success'
          })
        } catch (err) {
          return this.$logException(err, {
            message: errorMessage
          })
        }
      })
    },
    formatFetchBody(data) {
      let body = this.$deepClone(data)
      body.clockout_after_enabled = !!body.auto_clock_out_after
      body.clockout_at_enabled = !!body.auto_clock_out_at
      body.clockout_after = body.auto_clock_out_after?.value || 1
      body.clockout_at = body.auto_clock_out_at
        ? this.$date.formatTime(
            parse(body.auto_clock_out_at, 'HH:mm:ss', new Date())
          )
        : '12:00'
      return body
    },
    makeHandleableBody(form) {
      return {
        auto_clock_out: form.clockout_after_enabled || form.clockout_at_enabled,
        auto_clock_out_after: form.clockout_after_enabled
          ? {
              value: form.clockout_after,
              period: 'hours'
            }
          : null,
        auto_clock_out_at: form.clockout_at_enabled
          ? this.$date.formatDateWithTimezone(
              parse(form.clockout_at, 'HH:mm', new Date()),
              null,
              'HH:mm:ssxxxxx'
            )
          : null
      }
    },
    resetChanges() {
      this.$store.dispatch('Config/resetClientAccountConfiguration')
      // Could be that the language was changed and not saved
      this.$rootMaybeChangeLocale(this.$store.getters['Config/getLocale'])
    }
  }
}
</script>
