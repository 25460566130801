<template>
  <th-wrapper :title="$t('pages.settings.timetracking.edit.clockout.title')">
    <!-- Clockout at enabled -->
    <el-form-item prop="clockout_at_enabled" class="mb-1 leading-normal">
      <el-checkbox
        id="clockout_at_enabled"
        v-model="localValue.clockout_at_enabled"
        :label="
          $t('pages.settings.timetracking.edit.clockout.at_enabled.title')
        "
      />
    </el-form-item>

    <!-- Clockout at -->
    <el-form-item prop="clockout_at" class="mb-4">
      <time-input
        id="clockout_at"
        v-model="localValue.clockout_at"
        :allow-empty="!localValue.clockout_at_enabled"
        :disabled="!localValue.clockout_at_enabled"
        placeholder="00:00"
        class="w-40"
      />
    </el-form-item>

    <!-- Clockout after enabled -->
    <el-form-item prop="clockout_after_enabled" class="mb-1 leading-normal">
      <el-checkbox
        id="clockout_after_enabled"
        v-model="localValue.clockout_after_enabled"
        :label="$t('pages.settings.timetracking.edit.after_enabled.title')"
      />
    </el-form-item>

    <!-- Clockout after at -->
    <el-form-item prop="clockout_after" class="mb-0">
      <el-input-number
        id="clockout_after"
        v-model="localValue.clockout_after"
        data-testid="clockout_after"
        :min="1"
        :max="99"
        controls-position="right"
        class="w-40"
        :disabled="!localValue.clockout_after_enabled"
        @keypress="onlyInteger"
      />
    </el-form-item>
  </th-wrapper>
</template>

<script>
import TimeInput from '@/components/inputs/time-input'

export default {
  components: {
    TimeInput
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  methods: {
    onlyInteger(e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[0-9]*$/.test(char)) return true
      else e.preventDefault()
    }
  }
}
</script>

<style scoped>
.leading-normal :deep(.el-form-item__content) {
  line-height: 1;
}
</style>
